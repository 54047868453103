<template>
<div class="container">
    <div class="top-panel bg-s-cover">
        <mobile-header title="智能贴膜" />
        <div class="failure-icon bg-s-cover"></div>
        <div class="pay-tips-title center">支付失败</div>
        <!-- <div class="pay-tips-desc center">共支付： ¥0.00</div> -->
        <div class="phone-img-box bg-s-cover flex flex-a-i-center flex-j-c-center">
            <div class="phone-img bg-s-cover" :style="{backgroundImage: `url(./images/test/pay_success_phone.png)`}"></div>
        </div>
    </div>
    <div class="steady"></div>
    <div class="btn complete-btn bg-s-cover center">{{customServiceText}} </div>
    <!-- <div class="btn contact-btn bg-s-cover center">完成</div> -->
</div>
</template>

<script>
import MobileHeader from '@/components/MobileHeader.vue'
import { getProfileValue } from "../lib/util";
export default {
    data() {
        return {
            customServiceText: ''
        }
    },
    components: {
        MobileHeader
    },
    async created() {
        this.customServiceText = '联系客服：'+await this.$getProfileValue('PHONE-CUSTOMER_SERVICE');
    },
    methods: {

    }
}
</script>

<style lang="scss" scoped>
.container {
    $imgBoxHeight: 34.67vw;

    .top-panel {
        width: 100%;
        height: 82.67vw;
        background-image: url(~@/assets/mobile/pay_success/top_panel.png);
        background-position: bottom;
        background-repeat: no-repeat;
        position: relative;

        .failure-icon {
            width: 16.67vw;
            height: 16.67vw;
            background-image: url(~@/assets/mobile/pay_failure/failure_icon.png);
            margin: 8vw auto 0;
        }

        .pay-tips-title {
            font-size: 4.8vw;
            line-height: 4.8vw;
            font-weight: 600;
            color: #ffffff;
            margin-top: 8vw;
        }

        .pay-tips-desc {
            color: #ffffff;
            margin-top: 3.87vw;
            font-size: 3.73vw;
            line-height: 3.73vw;
        }

        .phone-img-box {
            width: 34.67vw;
            height: $imgBoxHeight;
            background-image: url(~@/assets/mobile/pay_success/img_box.png);
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translate(-50%, 50%);

            .phone-img {
                width: 11.47vw;
                height: 23.87vw;
            }
        }
    }

    .steady {
        padding-top: $imgBoxHeight/2;
    }

    .btn {
        width: 84vw;
        height: 10.67vw;
        font-size: 4.27vw;
        line-height: 10.67vw;
    }

    .complete-btn {
        color: #ffffff;
        background-image: url(~@/assets/mobile/pay_success/share_btn.png);
        margin: 26.67vw auto 0;
    }

    .contact-btn {
        background-image: url(~@/assets/mobile/pay_success/complete_btn.png);
        margin: 6.67vw auto 0;
    }

}
</style>
