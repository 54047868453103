<template>
<div class="top-nav flex flex-a-i-center flex-j-c-center">
    <div class="back-btn bg-s-cover" @click="$goBack()"></div>
    <div class="title">{{title}}</div>
</div>
</template>

<script>
export default {
    props: ['title']
}
</script>

<style lang="scss" scoped>
.top-nav {
    width: 100%;
    height: 11.73vw;
    position: relative;

    .back-btn {
        width: 2.8vw;
        height: 5.07vw;
        position: absolute;
        left: 2.67vw;
        top: 3.33vw;
        background-image: url(~@/assets/mobile/pay_success/back_icon.png);
    }

    .title {
        font-size: 4.27vw;
        color: #ffffff;
    }
}
</style>
