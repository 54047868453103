var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container" }, [
    _c(
      "div",
      { staticClass: "top-panel bg-s-cover" },
      [
        _c("mobile-header", { attrs: { title: "智能贴膜" } }),
        _c("div", { staticClass: "failure-icon bg-s-cover" }),
        _c("div", { staticClass: "pay-tips-title center" }, [
          _vm._v("支付失败")
        ]),
        _c(
          "div",
          {
            staticClass:
              "phone-img-box bg-s-cover flex flex-a-i-center flex-j-c-center"
          },
          [
            _c("div", {
              staticClass: "phone-img bg-s-cover",
              style: {
                backgroundImage: "url(./images/test/pay_success_phone.png)"
              }
            })
          ]
        )
      ],
      1
    ),
    _c("div", { staticClass: "steady" }),
    _c("div", { staticClass: "btn complete-btn bg-s-cover center" }, [
      _vm._v(_vm._s(_vm.customServiceText) + " ")
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }