var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "top-nav flex flex-a-i-center flex-j-c-center" },
    [
      _c("div", {
        staticClass: "back-btn bg-s-cover",
        on: {
          click: function($event) {
            _vm.$goBack()
          }
        }
      }),
      _c("div", { staticClass: "title" }, [_vm._v(_vm._s(_vm.title))])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }